<!--选择优惠券-->
<template>
    <div>
        <coupon-item v-for="item,index in validUserCouponList" :key="index"
                     :status="5"
                     :money="item.money"
                     :full_money="item.full_money"
                     :title="item.title"
                     :valid_end_time="item.valid_end_time"
                     :userCouponId="item.userCouponid"
                     :reason="item.reason"
                     :couponId="item.coupon"
        ></coupon-item>
        <coupon-item v-for="item,index in unvalidUserCouponList" :key="index"
                     :status="4"
                     :money="item.money"
                     :full_money="item.full_money"
                     :title="item.title"
                     :valid_end_time="item.valid_end_time"
                     :userCouponId="item.userCouponid"
                     :reason="item.reason"
                     :couponId="item.coupon"
        ></coupon-item>
    </div>
</template>

<script>
  import { globalConfig } from '../../utils/mixin'
  import { getNowBuyProduct } from '../../utils/cart'
  import CouponItem from '../../components/order/CouponItem'

  export default {
    name: 'SelectCoupon',
    components: { CouponItem },
    mixins: [globalConfig],
    data () {
      return {
        // 可用的
        validUserCouponList: [],
        // 不可用的
        unvalidUserCouponList: []
      }
    },
    methods: {
      init: function () {
        const goodsList = getNowBuyProduct()
        const that = this
        this.axios.post(this.apiUrl + 'mall/init/selectCoupon', {
          goods: goodsList
        }).then((response) => {
          const data = response.data
          that.validUserCouponList = data.validUserCouponList
          that.unvalidUserCouponList = data.unvalidUserCouponList
        })
      }
    },
    mounted () {
      this.init()
    }
  }
</script>

<style scoped>

</style>
